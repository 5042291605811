//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// This file was autogenerated by cuetsy. DO NOT EDIT!
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


export const modelVersion = Object.freeze([0, 0]);


export interface PanelOptions {
  feedUrl?: string;
  showImage?: boolean;
}

export const defaultPanelOptions: Partial<PanelOptions> = {
  showImage: true,
};
